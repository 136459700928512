<template>
	<div
		class="flex-column full-height bg-white position-relative"
	>
		<div class="full-height overflow-y-auto pa-20">

			<div class=" flex-row justify-space-between">
				<button
					class="flex-1 btn radius-l-20 pa-10"
					:class="item.card_div_type == 'personal' ? 'btn-primary' : 'btn-primary-outline'"
					@click="item.card_div_type = 'personal'"
				>개인</button>
				<button
					class="flex-1 btn btn-primary-outline radius-r-20 pa-10"
					:class="item.card_div_type == 'business' ? 'btn-primary' : 'btn-primary-outline'"
					@click="item.card_div_type = 'business'"
				>법인</button>
			</div>
			<div class="mt-20">
				<label
				>카드번호</label>
				<div class="flex-row justify-center mt-10 box radius-20 pa-10 text-center">
					<input
						v-model="item.card_number[0]"
						placeholder="0000"
						class="flex-1 text-center"
						type="number"
						:rules="$rules.numeric(item.card_number, 0, 4)"
						@keyup="onFocus($event, 4, 'card_number_1')"
					/>
					<input
						v-model="item.card_number[1]"
						placeholder="0000"
						class="flex-1 text-center"
						ref="card_number_1"
						type="password"
						:rules="$rules.numeric(item.card_number, 1, 4)"
						@keyup="onFocus($event, 4, 'card_number_2')"
					/>
					<input
						v-model="item.card_number[2]"
						placeholder="0000"
						class="flex-1 text-center"
						ref="card_number_2"
						type="password"
						:rules="$rules.numeric(item.card_number, 2, 4)"
						@keyup="onFocus($event, 4, 'card_number_3')"
					/>
					<input
						v-model="item.card_number[3]"
						placeholder="0000"
						class="flex-1 text-center"
						ref="card_number_3"
						type="number"
						:rules="$rules.numeric(item.card_number, 3, 4)"
						@keyup="onFocus($event, 4, 'valid_term_year')"
					/>
				</div>
			</div>
			<div class="mt-20">
				<div>
					<label
					>유효기간</label>
					<div class="mt-10 flex-row justify-space-betweens ">
						<input
							v-model="item.valid_term_year"
							:rules="$rules.numeric(item, 'valid_term_year', 2)"
							class="input-box mr-10 text-center"
							ref="valid_term_year"
							placeholder="mm"
							@keyup="onFocus($event, 2, 'valid_term_mm')"
						>
						<input
							v-model="item.valid_term_mm"
							:rules="$rules.numeric(item, 'valid_term_mm', 2)"
							class="input-box text-center"
							ref="valid_term_mm"
							placeholder="yy"
							@keyup="onFocus($event, 2, 'valid_cvc')"
						>
					</div>
				</div>
			</div>
			<div class="mt-20 flex-row justify-space-between">
				<div class="flex-1 mr-10">
					<label
					>CVC</label>
					<input
						v-model="item.valid_cvc"
						class="mt-10 input-box text-center"
						placeholder="CVC 코드"
						ref="valid_cvc"
						:rules="$rules.numeric(item, 'valid_cvc', 3)"
						@keyup="onFocus($event, 3, 'paswd')"
					>
				</div>
				<div class="flex-1">
					<label
					>비밀번호</label>
					<input
						v-model="item.paswd"
						type="password"
						class="mt-10 input-box text-center"
						placeholder="비밀번호 앞 2자리"
						ref="paswd"
						:rules="$rules.numeric(item, 'paswd', 2)"
						@keyup="onFocus($event, 2, 'personal_biz_certfc_info')"
					>
				</div>
			</div>
			<div
				v-if="item.card_div_type == 'personal'"
				class="mt-20"
			>
				<label
				>생년월일</label>
				<input
					v-model="item.personal_biz_certfc_info"
					type="password"
					class="mt-10 input-box"
					ref="personal_biz_certfc_info"
					placeholder="예시) 190101"
					:rules="$rules.numeric(item, 'personal_biz_certfc_info', 6)"
				>
			</div>
			<div
				v-else
				class="mt-20"
			>
				<label
				>사업자번호</label>
				<input
					v-model="item.personal_biz_certfc_info"
					type="password"
					class="mt-10 input-box"
					ref="personal_biz_certfc_info"
					placeholder="사업자번호 10자리"
					:rules="$rules.numeric(item, 'personal_biz_certfc_info', 10)"
				>
			</div>

			<div
				class="mt-20"
			>
				<label>상품명</label>
				<input
					v-model="item.pdt_name"
					class="mt-10 input-box"
					ref="pdt_name"
					placeholder="상품명을 입력하세요"
					max="20"
				>
			</div>

			<div
				class="mt-20"
			>
				<label>결제금액</label>
				<input
					v-model="item.pdt_price"
					type="number"
					class="mt-10 input-box"
					ref="pdt_price"
					placeholder="결제금액을 입력하세요"
					:rules="$rules.max(item, 'pdt_price', 9)"
				>
			</div>
		</div>
		<div
			class="mt-auto"
		>
			<button
				class="btn btn-primary"
				:disabled="is_disabled"
				@click="postPayment"
			>결제요청</button>
		</div>

		<PaymentResult
			v-if="is_on_result"
			:user="user"
			:payment_info="item"

		></PaymentResult>
	</div>
</template>
<script>
	import PaymentResult from "./PaymentResult";
	export default {
		name: 'PaymentItem'
		, components: {PaymentResult}
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: '수기결제'
					, title: '수기결제'
					, bottom: false
					, top: false
				}
				, item: {
					card_number: []
					, card_div_type: 'personal'
				}
				, is_on_result: false
			}
		}
		, computed: {
			is_disabled: function(){
				let t = false

				return t
			}
			, card_number: function(){
				let t = this.item.card_number.join('')

				return t
			}

		}
		, methods: {
			postPayment: async function(){
				try{
					this.$bus.$emit('on')
					let result = await this.$Axios({
						method: 'post'
						, url: '/member/postPayment'
						, data: {
							card_div_type: this.item.card_div_type
							, card_number: this.card_number
							,
						}
					})
					if(result.success){
						this.is_on_result = true
					}else{
						throw result.message
					}
				}catch (e){
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('off')
				}
			}
			, onFocus: function(e, max, target){
				if(e.target.value.length >= max){
					this.$refs[target].focus()
				}
			}

		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
		}
	}
</script>